import { makeApiRequest } from 'actions/api';
import { getRestartCampaign } from 'actions/restartCampaigns';
import RequestService from 'services/RequestService';

export const BOOTSTRAP_MESSAGE_CENTER = 'BOOTSTRAP_MESSAGE_CENTER';
export const BOOTSTRAP_MESSAGE_CENTER_SUCCESS = 'BOOTSTRAP_MESSAGE_CENTER_SUCCESS';
export const BOOTSTRAP_MESSAGE_CENTER_ERROR = 'BOOTSTRAP_MESSAGE_CENTER_ERROR';

export const GET_MESSAGES = 'GET_MESSAGES';
export const GET_MESSAGES_REQUEST = 'GET_MESSAGES_REQUEST';
export const GET_MESSAGES_RESPONSE = 'GET_MESSAGES_RESPONSE';
export const GET_MESSAGES_ERROR = 'GET_MESSAGES_ERROR';

export const REFRESH_MESSAGES = 'REFRESH_MESSAGES';
export const REFRESH_MESSAGES_REQUEST = 'REFRESH_MESSAGES_REQUEST';
export const REFRESH_MESSAGES_RESPONSE = 'REFRESH_MESSAGES_RESPONSE';
export const REFRESH_MESSAGES_ERROR = 'REFRESH_MESSAGES_ERROR';

export const UPDATE_MESSAGE = 'UPDATE_MESSAGE';
export const UPDATE_MESSAGE_REQUEST = 'UPDATE_MESSAGE_REQUEST';
export const UPDATE_MESSAGE_RESPONSE = 'UPDATE_MESSAGE_RESPONSE';
export const UPDATE_MESSAGE_ERROR = 'UPDATE_MESSAGE_ERROR';

export const EXPIRE_MESSAGE_REQUEST = 'EXPIRE_MESSAGE_REQUEST';
export const EXPIRE_MESSAGE_RESPONSE = 'EXPIRE_MESSAGE_RESPONSE';
export const EXPIRE_MESSAGE = 'EXPIRE_MESSAGE';
export const EXPIRE_MESSAGE_ERROR = 'EXPIRE_MESSAGE_ERROR';

export const MESSAGE_INTERACTION = 'MESSAGE_INTERACTION';

export const bootstrapMessageCenter = () => async dispatch => {
  dispatch({ type: BOOTSTRAP_MESSAGE_CENTER });
  try {
    const response = await dispatch(getMessages());
    if (response.ok) {
      const userMessages = response.userMessages;
      const pauseMessage = userMessages.find(message => message.name === 'pause');
      if (pauseMessage) {
        await dispatch(getRestartCampaign());
      }
    }
    dispatch({ type: BOOTSTRAP_MESSAGE_CENTER_SUCCESS });
  } catch {
    dispatch({ type: BOOTSTRAP_MESSAGE_CENTER_ERROR });
  }
};

export const getMessages = () =>
  makeApiRequest({
    requestType: GET_MESSAGES,
    method: RequestService.getMessages,
    redirectOnUnauthorized: false,
  });

export const refreshMessages = () =>
  makeApiRequest({
    requestType: REFRESH_MESSAGES,
    method: RequestService.getMessages,
    redirectOnUnauthorized: false,
  });

export const updateMessage = params =>
  makeApiRequest({
    requestType: UPDATE_MESSAGE,
    method: RequestService.updateMessage,
    params,
  });

export const expireMessage = message_id =>
  makeApiRequest({
    requestType: EXPIRE_MESSAGE,
    method: RequestService.updateMessage,
    params: { id: message_id, expire: true },
  });

export const batchUpdateMessages = messagesParams => dispatch =>
  Promise.all(messagesParams.map(params => dispatch(updateMessage(params))));

export const messageInteraction = message => ({ type: MESSAGE_INTERACTION, message });
