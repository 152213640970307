import { combineReducers } from 'redux';

import mealOptions from './mealOptions';
import servings from './servings';
import subtotals from './subtotals';
import suggestedPairings from './suggestedPairings';
import dirty from './dirty';

export default combineReducers({
  servings,
  mealOptions,
  subtotals,
  suggestedPairings,
  dirty,
});

export const selectServings = state => state.servings;
export const selectMealOptions = state => state.mealOptions;
export const selectSubtotals = state => state.subtotals;
export const selectSuggestedPairings = state => state.suggestedPairings;
export const selectBasketDirty = state => state.dirty;
