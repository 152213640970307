const CloseX = ({ className = '' }) => (
  <svg viewBox="0 0 24 24" className={className} role="presentation" aria-hidden={true}>
    <title>Close</title>
    <path
      data-testid="close-icon"
      fill="currentColor"
      d="M11.9999996,10.5857861 L17.2928929,5.29289286 C17.6834172,4.90236857 18.3165821,4.90236857 18.7071064,5.29289286 C19.0976307,5.68341715 19.0976307,6.31658213 18.7071064,6.70710642 L13.4142132,11.9999996 L18.7071064,17.2928929 C19.0976307,17.6834172 19.0976307,18.3165821 18.7071064,18.7071064 C18.3165821,19.0976307 17.6834172,19.0976307 17.2928929,18.7071064 L11.9999996,13.4142132 L6.70710642,18.7071064 C6.31658213,19.0976307 5.68341715,19.0976307 5.29289286,18.7071064 C4.90236857,18.3165821 4.90236857,17.6834172 5.29289286,17.2928929 L10.5857861,11.9999996 L5.29289286,6.70710642 C4.90236857,6.31658213 4.90236857,5.68341715 5.29289286,5.29289286 C5.68341715,4.90236857 6.31658213,4.90236857 6.70710642,5.29289286 L11.9999996,10.5857861 Z"
    />
  </svg>
);

CloseX.propTypes = {
  className: PropTypes.string,
};

export default CloseX;
