import isEqual from 'lodash/isEqual';

import { SET_CLEAN_BASKET_EDIT_STATE, HAS_BASKET_STATE_CHANGED } from 'actions/basketEdits';

const initialState = {
  mealServings: {},
  mealOptions: {},
  isDirty: false,
};

const hasStateChanged = (state, updatedMealServings, updatedMealOptions) => {
  return !(
    isEqual(state.mealServings, updatedMealServings) &&
    isEqual(state.mealOptions, updatedMealOptions)
  );
};

export default (state = initialState, action) => {
  switch (action.type) {
    case HAS_BASKET_STATE_CHANGED: {
      return {
        ...state,
        isDirty: hasStateChanged(state, action.mealServings, action.mealOptions),
      };
    }
    case SET_CLEAN_BASKET_EDIT_STATE: {
      return {
        ...state,
        mealServings: action.mealServings,
        mealOptions: action.mealOptions,
        isDirty: false,
      };
    }
    default:
      return state;
  }
};
