export const CUTOFF_DATE_FORMAT = 'dddd MMMM Do, YYYY';
export const THANK_YOU_CUTOFF_DATE_FORMAT = 'dddd, MMMM Do';
export const WEEK_OF_DELIVERY_DATE_FORMAT = 'MMMM Do';
export const CUTOFF_TIME_FORMAT = 'LT';
export const CUTOFF_TIME_WITH_AMPM_FORMAT = 'LT a';
export const CUTOFF_TIME_FORMAT_SHORT = 'h A';
export const DELIVERY_DATE_FORMAT = 'dddd, MMM Do';
export const SHORT_DELIVERY_DATE_FORMAT = 'ddd, MMM Do';
export const MENU_PARSE_FORMAT = 'DD-MMM-YYYY';
export const SHORT_DAY_LONG_MONTH_DELIVERY_DATE_FORMAT = 'ddd, MMMM DD';
export const SHORT_DAY_LONG_MONTH_ORDINAL_SUFFIX = 'ddd, MMMM Do';
