const originalScroll = window.scroll;

window.scroll = function (firstArg, secondArg) {
  if (typeof firstArg === 'object') {
    try {
      if ('scrollBehavior' in document.documentElement.style) return originalScroll(firstArg);
      throw 'not supported';
    } catch {
      originalScroll(firstArg.left || 0, firstArg.top || 0);
    }
  } else {
    originalScroll(firstArg, secondArg);
  }
};
